/* Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
A copy of the License is located at

    http://www.apache.org/licenses/LICENSE-2.0

or in the "license" file accompanying this file. This file is distributed
on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
express or implied. See the License for the specific language governing
permissions and limitations under the License. */
html, body, #root{
  height: 100%;
}
#root{
  display: flex;
  justify-content:center;
  align-items:center;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #463E40;
  background-color: #F5F5F5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*
 * See full list: https://docs.amplify.aws/ui/customization/theming/q/framework/react#supported-css-custom-properties
 */
:root{
  --amplify-primary-color: #246f28;
  --amplify-secondary-color: #263238;
  --amplify-primary-tint: #287C2D;
  --amplify-primary-shade: #246f28; /* active btn */
  --amplify-background-color-active: #287C2D;
  --background-color-active: #287C2D;
  --background-color: #287C2D;
  --amplify-secondary-tint: #8d8d8d;
  --close-icon-hover: #263238;
  --amplify-text-sm: 16px;
}

/*
 * See full list: https://docs.amplify.aws/ui/auth/authenticator/q/framework/react#props-css-amplify-authenticator
 */
amplify-authenticator {
	--border-radius: 0px;
	--box-shadow: 0px 0px 0px 0 rgba(0, 0, 0, 0.15);
	--padding: 0px 16px 0px 16px;
	--margin-bottom: 0px;
	--container-display: flex;
	--container-justify: center;
	--container-align: center;
	--container-height: auto;
  --amplify-background-color-active: #287C2D;
  --background-color: #287C2D;
  border-radius: 0 !important;
}


input,
.btn {
  width: 100%;
  padding: 12px;
  opacity: 1;
  border: none;
  border-radius: 4px;
  margin: 5px 0;
  display: inline-block;
  font-size: 17px;
  line-height: 20px;
  text-decoration: none;
}

input:hover,
.btn:hover {
  opacity: 0.85;
  cursor: pointer;
}

.fb {
  background-color: #3B5998;
  color: white;
}

.amazon {
  background-color: #287C2D;
  color: white;
}

.saml {
  background-color:#232f3e;
  color: white;
}

.google {
  background-color: #dd4b39;
  color: white;
}

/* CUSTOM DMA */
.sign-in-form-footer{
  flex-direction: column !important;
}



/* LOADER */
.loaderTn {
  animation: rotate 1s infinite;
  height: 50px;
  width: 50px;
}

.loaderTn:before,
.loaderTn:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}
.loaderTn:before {
  animation: ball1 1s infinite;
  background-color: #fff;
  box-shadow: 30px 0 0 #255900;
  margin-bottom: 10px;
}
.loaderTn:after {
  animation: ball2 1s infinite;
  background-color: #255900;
  box-shadow: 30px 0 0 #fff;
}

@keyframes rotate {
  0% { transform: rotate(0deg) scale(0.8) }
  50% { transform: rotate(360deg) scale(1.2) }
  100% { transform: rotate(720deg) scale(0.8) }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 #255900;
  }
  50% {
    box-shadow: 0 0 0 #255900;
    margin-bottom: 0;
    transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #255900;
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #fff;
  }
  50% {
    box-shadow: 0 0 0 #fff;
    margin-top: -20px;
    transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #fff;
    margin-top: 0;
  }
}

.customToast {
  display: flex;
    justify-content: space-between;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 99;
    box-shadow: rgb(0 0 0 / 30%) 0px 0px 5px 0px;
    padding: 16px;
    background-color:var(--amplify-secondary-tint);
    font-size: var(--font-size);
    color: var(--color);
    box-sizing: border-box;
    border-radius: 5px;
    font-family: var(--font-family);
}
.customToast span{
  color: white;
}
.customToastClose {
  margin-left: auto;
  align-self: center;
  position: relative;
  width: 18px;
  height: 18px;
  overflow: hidden;
  cursor: pointer;
  background: transparent;
  border: none;
}

.customToastClose:before,.customToastClose:after{
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0px;
  margin-top: -1px;
  background: white;
}
.customToastClose:before {
  transform: rotate(45deg);
}
.customToastClose:after {
  transform: rotate(-45deg);
}

.MuiFormLabel-root.Mui-focused{
  color: #287C2D !important;
}
.MuiInput-underline:hover:not(.Mui-disabled):before,.MuiInput-underline:after{
  border-bottom-color: #287C2D !important;
}